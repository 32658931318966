.mobile-show-vendor{

   h1 {
      margin: 0;
      font-size: 1.6875rem;
      line-height: 1.8rem;

      font-weight: 600;
      text-align: left;

      
   }

   ion-thumbnail {
      // --border-radius: rem!important;
      --size: 100%;
   }

   .flex {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: red;

      height: 100%;
   }

   ion-list strong {
      display: block;
   }

   ion-list ion-item {
      // padding: 0 1rem;
   }





}