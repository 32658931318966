.chat-thumbnail {
   --border-radius: 50%;
}

.chat-mobile-page ion-card {
   margin: .3rem;
}

.chat-desktop-page  {
   ion-card {margin: .3rem;}

   background-color: white;
   border-radius: 10px;
   padding: 1rem;

   height: 600px;
   max-height: 600px;

   overflow-y: scroll;
  
}


#chat-page-desktop {
   form { 
      margin-top: 1rem;
      border-radius: 10px;

      ion-item {
         --border-radius: 10px;
      }
   }
   // form { 
   //    position: fixed;
   //    bottom: 0;
   //    width: 70%;
   //    left: 15%;

   // }
}